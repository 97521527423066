import Macaron from "./Macaron";

export default class MacaronRepository {

    macarons = new Array<Macaron>();

    constructor(private onLoad: () => void) {
        this.loadData();
    }

    private loadData() {
        let url = "macaronData.csv";
        fetch(url)
            .then(response => response.text())
            .then(data => this.fillMacarons(data));
    }

    private fillMacarons(data: string) {
        data.split("\n").forEach((line, i) => {
            if (i === 0 || line.length < 2)
                return;

            this.macarons.push(new Macaron(line))
        });
        this.onLoad();
    }
}