export default class Macaron {

    name: string;
    flavor: string;
    sideOne: string;
    filling: string;
    sideTwo: string;
    imageUrl: string;
    altImageUrl: string;
    detailsUrl: string;

    constructor(tsvLine: string) {
        let sections = tsvLine.split(";");
        this.name = sections[0];
        this.flavor = sections[1];
        this.sideOne = sections[2];
        this.filling = sections[3];
        this.sideTwo = sections[4];
        this.imageUrl = sections[5];
        this.altImageUrl = sections[6];
        this.detailsUrl = sections[7];
    }
}