import React from 'react';
import './App.css';
import './fonts/fonts.css';
import FilterData from './model/FilterData';
import Filter from './components/Filter';
import Macaron from './model/Macaron';
import MacaronRepository from './model/MacaronRepository';
import ResultList from './components/ResultList';
import LanguageSelector from './components/LanguageSelector';
import t, { TranslationRepository } from './model/TranslationRepository';

interface State {
    languageCode: string;
    macarons: Macaron[];
    filter: FilterData;
}

let defaultLanguageCode = "hu";

export default class App extends React.Component<{}, State> {

    static languageCode = "default";
    static translationRepository: TranslationRepository | undefined;

    private easterClickCount = 0;

    constructor(props: {}) {
        super(props);
        defaultLanguageCode = this.getLanguageCodeFromUrl(defaultLanguageCode);
        this.state = {
            languageCode: "default",
            macarons: new Array<Macaron>(),
            filter: this.getFilterFromHash()
        };
    }

    componentDidMount() {
        let repo = new MacaronRepository(() => {
            this.setState({
                macarons: repo.macarons
            });
        });
        App.translationRepository = new TranslationRepository(() => {
            App.languageCode = defaultLanguageCode;
            this.setState({
                languageCode: defaultLanguageCode
            });
        })
    }

    onFilterChange = (newFilter: FilterData) => {
        this.setState({
            filter: newFilter
        });
        this.setHashByFilter(newFilter);
    }

    onResetFilters = () => {
        let newFilter = new FilterData();
        this.setState({
            filter: newFilter
        });
        this.scrollToTop();
        this.setHashByFilter(newFilter);
    }

    onEasterClick = () =>{
        this.easterClickCount++;
        if(this.easterClickCount >= 5) {
            this.easterClickCount = 0;
            alert("Orsi & Ádám 2021\nJó macaronozást mindenkinek! 😊");
        }
    }

    private scrollToTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    private setHashByFilter(filter: FilterData) {
        window.location.hash = filter.toHash();
    }

    private getFilterFromHash(): FilterData {
        return FilterData.fromHash(window.location.hash);
    }

    onLanguageChange = (code: string) => {
        App.languageCode = code;
        this.setState({
            languageCode: code
        });
        this.setLanguageCode(code);
    }

    render() {
        return (
            <div className="app">
                <LanguageSelector onChange={this.onLanguageChange}></LanguageSelector>
                <header className="header">
                    <h1>My Macaron</h1>
                    <div><small><span onClick={this.onEasterClick}>for</span> <a href={t("chez dodo url")}>Chez Dodo</a></small></div>
                    <div className="intro">
                        <p>
                            {t("Ismerd meg macaronjaidat!")}<br />{t("Vajon megtalálod őket? 😉")}
                        </p>
                    </div>
                </header>
                <Filter macarons={this.state.macarons} filter={this.state.filter} onFilterChange={this.onFilterChange}></Filter>
                <ResultList macarons={this.state.macarons} filter={this.state.filter} onResetFilters={this.onResetFilters}></ResultList>
            </div>
        )
    }

    private getLanguageCodeFromUrl(fallbackCode: string): string {
        if (localStorage) {
            var storedCode = localStorage.getItem("mymacaron_lang_code");
            if (storedCode)
                fallbackCode = storedCode;
        }

        if (!URLSearchParams)
            return fallbackCode;

        var params = new URLSearchParams(window.location.search);
        var code = params.get("lang");
        if (!code || code.length !== 2)
            return fallbackCode;

        this.setLanguageCode(code);
        return code;
    }

    private setLanguageCode(code: string) {
        if (localStorage)
            localStorage.setItem("mymacaron_lang_code", code);
    }
}