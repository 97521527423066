import React from "react";
import FilterData from "../model/FilterData";
import Macaron from "../model/Macaron";
import t from "../model/TranslationRepository";
import Dropdown from "./Dropdown";

interface Props {
    macarons: Macaron[];
    filter: FilterData;
    onFilterChange: (filter: FilterData) => void;
}

export default class Filter extends React.Component<Props> {

    onSideOneChange = (newValue: string) => {
        let f = this.props.filter.clone();
        f.sideOne = newValue;
        f.filling = "";
        f.sideTwo = "";
        this.props.onFilterChange(f);
    }

    onFillingChange = (newValue: string) => {
        let f = this.props.filter.clone();
        f.filling = newValue;
        f.sideTwo = "";
        this.props.onFilterChange(f);
    }

    onSideTwoChange = (newValue: string) => {
        let f = this.props.filter.clone();
        f.sideTwo = newValue;
        this.props.onFilterChange(f);
    }

    getSideOneOptions = () => {
        let sideOne = this.props.macarons.map(x => x.sideOne);
        let sideTwo = this.props.macarons.map(x => x.sideTwo);
        return sideOne
            .concat(sideTwo)
            .filter(this.onlyUnique)
            .sort()
            .concat(["Nem tudom eldönteni"]);
    }

    getFillingOptions = () => {
        if (!this.props.filter.sideOne)
            return [];

        return this.props.macarons
            .filter(x => x.sideOne === this.props.filter.sideOne || x.sideTwo === this.props.filter.sideOne || this.props.filter.sideOne === "Nem tudom eldönteni")
            .map(x => x.filling)
            .filter(this.onlyUnique)
            .sort()
            .concat(["Nem tudom eldönteni"]);
    }

    getSideTwoOptions = () => {
        let sideOne = this.props.filter.sideOne;
        let filling = this.props.filter.filling;

        if (!sideOne || !filling)
            return [];
        
        let sideOneMatchedMacarons = this.props.macarons.filter(x => (x.sideOne === sideOne || sideOne === "Nem tudom eldönteni") && (x.filling === filling || filling === "Nem tudom eldönteni")).map(x => x.sideTwo);
        let sideTwoMatchedMacarons = this.props.macarons.filter(x => (x.sideTwo === sideOne || sideOne === "Nem tudom eldönteni") && (x.filling === filling || filling === "Nem tudom eldönteni")).map(x => x.sideOne);

        return sideOneMatchedMacarons
            .concat(sideTwoMatchedMacarons)
            .filter(this.onlyUnique)
            .sort();
    }

    private onlyUnique = (value: string, index: number, self: string[]) => {
        return self.indexOf(value) === index;
    }

    render() {
        return (
            <form>
                <Dropdown serial={1} title={t("Milyen színű a macaron kalapja?")} options={this.getSideOneOptions()} value={this.props.filter.sideOne} onChange={this.onSideOneChange}></Dropdown>
                <Dropdown serial={2} title={t("Milyen színű a macaron krémje?")} options={this.getFillingOptions()} value={this.props.filter.filling} onChange={this.onFillingChange}></Dropdown>
                <Dropdown serial={3} title={t("Milyen színű a másik kalapja?")} options={this.getSideTwoOptions()} value={this.props.filter.sideTwo} onChange={this.onSideTwoChange}></Dropdown>
            </form>
        );
    }
}