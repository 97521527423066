export default class FilterData {

    sideOne: string = "";
    filling: string = "";
    sideTwo: string = "";

    static fromHash(hash: string): FilterData {
        let n = new FilterData();
        if (!hash || hash.length <= 3)
            return n;

        let decoded = decodeURI(hash.substr(1));
        let sections = decoded.split("_");
        if (sections.length < 3)
            return n;

        n.sideOne = sections[0];
        n.filling = sections[1];
        n.sideTwo = sections[2];
        return n;
    }

    clone() {
        let n = new FilterData();
        n.sideOne = this.sideOne;
        n.filling = this.filling;
        n.sideTwo = this.sideTwo;
        return n;
    }

    isEmpty() {
        return !this.sideOne && !this.filling && !this.sideTwo;
    }

    toHash() {
        return encodeURI([this.sideOne, this.filling, this.sideTwo].join("_"))
    }
}