import React from "react";
import FilterData from "../model/FilterData";
import Macaron from "../model/Macaron";
import t from "../model/TranslationRepository";
import MacaronDisplay from "./MacaronDisplay";
import "./ResultList.css"

interface Props {
    macarons: Macaron[];
    filter: FilterData;
    onResetFilters: () => void;
}

export default class ResultList extends React.Component<Props> {

    private getResults = () => {
        // No filter is set
        if (this.props.filter.isEmpty())
            return this.props.macarons;

        let macarons= this.props.macarons;
        let sideOne = this.props.filter.sideOne;
        let filling = this.props.filter.filling;
        let sideTwo = this.props.filter.sideTwo;

        // Only one side (and optionally filling) is set
        if (!sideTwo) {
            return macarons
                .filter(x => x.sideOne === sideOne || x.sideTwo === sideOne || sideOne === "Nem tudom eldönteni")
                .filter(x => filling ? (x.filling === filling || filling === "Nem tudom eldönteni") : true)
        }

        // Both sides are set
        let normalMatched = macarons.filter(x => (x.sideOne === sideOne || sideOne === "Nem tudom eldönteni") && (x.filling === filling || filling === "Nem tudom eldönteni") && x.sideTwo === sideTwo);
        let reverseMatched = macarons.filter(x => x.sideOne === sideTwo && (x.filling === filling || filling === "Nem tudom eldönteni") && (x.sideTwo === sideOne || sideOne === "Nem tudom eldönteni"));
        return normalMatched.concat(reverseMatched).filter(this.onlyUnique);
    }

    private onlyUnique = (value: Macaron, index: number, self: Macaron[]) => {
        return self.indexOf(value) === index;
    }

    render() {

        // Animate
        setTimeout(() => {
            document.querySelector(".display-list")?.classList.add("flash");
            setTimeout(() => {
                document.querySelector(".display-list")?.classList.remove("flash");
            }, 1000);
        }, 0);

        let ending;
        if (this.props.filter.sideOne) {
            ending = <div className="more-results">
                        <div>
                            {t("Nem találod, amit keresel? Próbáld meg szűrés nélkül!")}
                        </div>
                        <div>
                            <button onClick={this.props.onResetFilters}>{t("Szűrők visszaállítása")}</button>
                        </div>
                    </div>;
        } else {
            ending = <div className="more-results">
                        <div>
                            {t("A Chez Dodoban a macaronok kézzel készülnek, így előfordulhatnak árnyalatnyi különbségek. Ha nem találod, amit keresel, érdeklődj az üzletben!")}
                        </div>
                       
                    </div>;
        }

        return (
            <div>
                <h2>{t("A Te macaronod lehet:")}</h2>
                <div className="display-list">
                    {this.getResults().map(x => <MacaronDisplay key={x.name} macaron={x}></MacaronDisplay>)}
                </div>
                {ending}
                <div className="shop-address">
                    <div>
                        1051 BUDAPEST, SAS U. 7.
                    </div>
                    <div>
                        <a href="tel:+36703413845">+36 70 341 3845</a>
                    </div>
                    <div>
                        <a href="mailto:macaron@chezdodo.hu">macaron@chezdodo.hu</a>
                    </div>
                </div>
            </div>
        );
    }
}