import React from "react";
import t from "../model/TranslationRepository";
import "./Dropdown.css"

interface Props { 
    serial: number,
    title: string, 
    options: string[], 
    value: string, 
    onChange: (value: string) => void 
}

export default class Dropdown extends React.Component<Props> {

    onChange = (e: any) => {
        this.props.onChange(e.target.value);
    }

    private capitalizeFirstLetter(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    render() {
        return (
            <div className={"dropdown macaron-part-" + this.props.serial}>
                <div className="left">
                    <div className="serial">{this.props.serial}.</div>
                </div>
                <div className="right">
                    <div className="question">{this.props.title}</div>
                    <div className="select-wrapper">
                        <select value={this.props.value} onChange={this.onChange} disabled={this.props.options.length < 1}>
                            <option value="">{t("Válassz")}</option>
                            {this.props.options.map(x => <option key={x} value={x}>{this.capitalizeFirstLetter(t(x))}</option>)}
                        </select>
                    </div>
                </div>
            </div>
        );
    }
}