import React from "react";
import App from "../App";
import "./LanguageSelector.css"

interface Props {
    onChange: (code: string) => void 
}

export default class LanguageSelector extends React.Component<Props> {

    private static languages = [
        {
            code: "en",
            name: "eng"
        },
        {
            code: "hu",
            name: "hun"
        }
    ];

    onChange = (code: any) => {
        this.props.onChange(code);
    }

    render() {
        return (
            <div id="language-selector">
                {LanguageSelector.languages.map(lang => 
                    <div key={lang.code}>
                        <button className={App.languageCode === lang.code ? "active" : "inactive"} onClick={() => this.onChange(lang.code)}>{lang.name}</button>
                    </div>
                )}
            </div>
        );
    }
}