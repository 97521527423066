import React from "react";
import Macaron from "../model/Macaron";
import t from "../model/TranslationRepository";
import "./MacaronDisplay.css"

interface Props {
    macaron: Macaron;
}

export default class MacaronDisplay extends React.Component<Props> {

    private transformMacaronUrl(original: string): string {
        return original.replace("macaronok", t("macaronok URL tag"));
    }

    render() {
        return (
            <div className="macaron-display" style={{backgroundImage: 'url('+this.props.macaron.imageUrl+')'}}>
                <div className="hover" style={{backgroundImage: 'url('+this.props.macaron.altImageUrl+')'}}></div>
                <div className="frame"></div>
                <div className="details">
                    <div className="text">
                        <div className="name">{t(this.props.macaron.name)}</div>
                        <div className="flavor">{t(this.props.macaron.flavor)}</div>
                    </div>
                </div>
                <a href={this.transformMacaronUrl(this.props.macaron.detailsUrl)}></a>
            </div>
        );
    }
}