import App from "../App";

export type LanguageDictionary = { [text: string]: { [languageCode: string]: string } };

export class TranslationRepository {

    languageCodes: string[] = [];
    dictionary: LanguageDictionary = {};
    ready = false;

    constructor(private onLoad: () => void) {
        this.loadData();
    }

    private loadData() {
        let url = "translations.csv";
        fetch(url)
            .then(response => response.text())
            .then(data => this.fillDictionary(data));
    }

    private fillDictionary(data: string) {
        data.split("\n").forEach((line, i) => {
            if (line.length < 2)
                return;

            if (i === 0) {
                this.parseLanguageCodes(line);
                return;
            }

            this.parseLine(line);
        });
        this.ready = true;
        this.onLoad();
    }

    private parseLanguageCodes(csvLine: string) {
        let sections = csvLine.split(";");

        for (let i = 1; i < sections.length; i++) {
            this.languageCodes.push(sections[i].trimEnd());
        }
    }

    private parseLine(csvLine: string) {
        let sections = csvLine.split(";");
        let text = sections[0];
        this.dictionary[text] = {};

        for (let i = 0; i < this.languageCodes.length; i++) {
            const translation = sections[i + 1];
            this.dictionary[text][this.languageCodes[i]] = translation.trimEnd();
        }
    }
}

export default function t(text: string): string {
    let code = App.languageCode;
    let translationRepository = App.translationRepository;
    
    if (!translationRepository || !translationRepository.ready)
        return text;

    if (!translationRepository.dictionary[text] || ! translationRepository.dictionary[text][code])
        return text;
        
    return translationRepository.dictionary[text][code];
}